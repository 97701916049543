import { render, staticRenderFns } from "./ReservationList.vue?vue&type=template&id=0960efe7&scoped=true"
import script from "./ReservationList.vue?vue&type=script&lang=js"
export * from "./ReservationList.vue?vue&type=script&lang=js"
import style0 from "./ReservationList.vue?vue&type=style&index=0&id=0960efe7&prod&lang=scss&scoped=true"
import style1 from "./ReservationList.vue?vue&type=style&index=1&id=0960efe7&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0960efe7",
  null
  
)

export default component.exports